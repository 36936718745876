// export const paymentDetails = {
//     gpay:"ycfltmm72k63@idbi",
//     paytm:"ycfltmm72k63@idbi",
//     phonepe:"ycfltmm72k63@idbi",
//     upi:"ycfltmm72k63@idbi"
// }
export const paymentDetails = {
    gpay:"BHARATPE09902679913@yesbankltd",
    paytm:"BHARATPE09902679913@yesbankltd",
    phonepe:"BHARATPE09902679913@yesbankltd",
    upi:"amitjagatiya@utkarshbank"
}
